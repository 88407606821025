const formatTime = (timeSeconds) => {
  const hours = Math.floor(timeSeconds / 3600);
  const minutes = Math.floor(timeSeconds / 60 - hours * 60);
  return `${hours}h ${minutes}min`;
};
const formatDistance = (distanceM) => {
  const distanceKm = distanceM / 1000;
  return `${Math.round(distanceKm * 100) / 100}km`;
};
const unique = (arr) => [...new Set(arr)];

const seasonStart = new Date("2023-04-01");
const now = new Date();
const diff = now.getTime() - seasonStart.getTime();
const currentDay = Math.ceil(diff / (1000 * 3600 * 24));

const range = (start, end) =>
  Array.from({ length: end - start + 1 }, (_, i) => i);

const calculateTotalStats = (trips, stations) => {
  const totalStationCount = stations.length;
  const visitedStationsCount = new Set(
    trips.flatMap((t) => [
      t.departureStation.stationId,
      t.returnStation.stationId,
    ])
  ).size;

  const totalTime = trips.map((trip) => trip.duration).reduce((a, b) => a + b);
  const totalDistance = trips
    .map((trip) => trip.distance)
    .reduce((a, b) => a + b);

  return {
    totalStationCount,
    visitedStationsCount,
    totalTime,
    totalDistance,
    currentDay,
  };
};

const calculateDailyStats = (trips) => {
  const days = range(1, currentDay).map((i) => {
    const date = new Date(seasonStart);
    date.setDate(date.getDate() + i);
    return date.toISOString().substring(0, 10);
  });
  const visitedStationIds = [];
  const dailyStats = [];
  for (const date of days) {
    const dayTrips = trips.filter((trip) => trip.departureDate === date);
    const newStationIds = unique(
      dayTrips.flatMap((trip) => [
        trip.departureStation.stationId,
        trip.returnStation.stationId,
      ])
    ).filter((station) => !visitedStationIds.includes(station));
    visitedStationIds.push(...newStationIds);
    dailyStats.push({
      date,
      totalDistance: dayTrips.reduce((acc, curr) => curr.distance + acc, 0),
      newStationIds,
    });
  }
  return dailyStats;
};

const renderDailyStats = (dailyStats) => {
  document.getElementById("daily-stats-table").innerHTML = `
  <table>
  <thead><td>Päivä</td><td>Matka</td><td>Uudet asemat</td>
  <td><a href="#" onClick="document.getElementById('daily-stats-table').classList.add('closed')">x</a></thead>
  ${dailyStats
    .map(
      (day) =>
        `<tr><td>${day.date}</td><td>${formatDistance(
          day.totalDistance
        )}</td><td>${day.newStationIds.length}</tr>`
    )
    .join("\n")}
  </table>
  `;
};

const renderStats = (stats) => {
  document.getElementById(
    "stat-stations-visited"
  ).innerHTML = `${stats.visitedStationsCount} / ${stats.totalStationCount}`;

  document.getElementById("stat-time-spent").innerHTML = formatTime(
    stats.totalTime
  );
  document.getElementById("stat-distance").innerHTML = formatDistance(
    stats.totalDistance
  );

  document.getElementById("stat-current-day").innerHTML =
    stats.currentDay.toString();
};

export const initStats = (trips, stations) => {
  const stats = calculateTotalStats(trips, stations);
  const dailyStats = calculateDailyStats(trips);
  renderStats(stats);
  renderDailyStats(dailyStats);
};
